import React, { useEffect, useState } from 'react';
import { log } from '../../../utility';
import axios from 'axios';
import { Base } from '../../Layouts';
import { Switch, useLocation } from 'react-router-dom';
import { RouteBasic } from '../../Wrappers';
import { Min } from '../../Layouts';
import FourOhFour from '../FourOhFour'
import Header from './components/Header';
import Navigation from './components/Navigation';
import Root from './routes/Root';
import Construction from './routes/Construction';
import Property from './routes/Property';
import Documents from './routes/Documents';
import PaymentHistory from './routes/PaymentHistory'
import Credit from './routes/Credit';
import './index.scss';
import TransitionIn from '../../../components/shared/TransitionIn';

export default function Deal(props) {


    // variables from props
    const id = props.match.params.id;

    // root URL for all /deals/ links
    const dealURL = process.env.REACT_APP_SESSION_URL + '/deal/' + id;

    // state variables from API
    const [deal, setDeal] = useState(null);
    const [members, setMembers] = useState(null);
    const [dealHistory, setDealHistory] = useState(null);
    const [LOCNeeded, setLOCNeeded] = useState(null);
    // const [LOCRedirect, setLOCRedirect] = useState(false);

    const location = useLocation();

    // ----- Deal Details ----- ----- ----- ----- ----- ----- ---->
    function fetchDealDetails(id) {

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";

        axios.post('/lima/api/deals/details', {
            dealid: id
        }).then((data) => {
            const dealDetails = data.data.deal[0];
            setDeal(dealDetails);
        }).catch((error) => {
            setDeal(false);
        })
    };

    function fetchDealsLOC(borrower_id) {

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";

        // check for LOC needed
        axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/loc', {
            borrower_id: borrower_id
        }).then((res) => {
            if (!(res.data.loc || res.data.underwriting)) {
                setLOCNeeded(true)
            } else {
                setLOCNeeded(false);
            }
        })
    };

    // ----- Deal Draws ----- ----- ----- ----- ----- ----- ---->
    function fetchDealMembers(id) {

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";

        axios.post('/lima/api/deals/members', {
            dealid: id
        }).then((data) => {
            setMembers(data.data.deals);
        }).catch(function (error) {
            setMembers(false);
        });
    };

    function fetchDealPaymentHistory(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";

        axios.post('/lima/api/deals/account-history', { dealid: id })
            .then((res) => {

                let dataFiltered;

                // preliminary filtering...
                dataFiltered = res.data.filter(x => !(x.Source_Type === "FinChg" || x.Source_Type === "Fundin"));
                dataFiltered = dataFiltered.filter(x => x.Source_Type !== "BEG");
                dataFiltered = dataFiltered.filter(x => x.Source_Type !== "DIS")

                // get year from each entry's "Date_Received" and add to entry as "year"
                dataFiltered.forEach(entry => {
                    entry.year = entry.Date_Received.substring(0, 4);
                });

                // group each entry by the new "year property"
                const groupBy = function (array, property) {
                    const groupObject = array.reduce((r, a) => {
                        r[a[property]] = [...r[a[property]] || [], a];
                        return r;
                    }, {});

                    const groupArray = [];

                    for (const [key, value] of Object.entries(groupObject)) {
                        groupArray.push({
                            year: key,
                            payments: value
                        })
                    }
                    return groupArray;
                };

                const sortDescending = ((arr, property) => {
                    return arr.sort((a, b) => (parseInt(b[property]) - parseInt(a[property])))
                });

                setDealHistory(sortDescending(groupBy(dataFiltered, "year", false), "year"));
            })
            .catch(error => {
                setDealHistory(false);
            });
    };

    // initial deal API calls when deal Id prop changes
    useEffect(() => {
        setDeal(null);
        setMembers(null);
        setLOCNeeded(null);

        fetchDealDetails(id);
        fetchDealPaymentHistory(id);
    }, [id])

    // watch for deal to load and fire LOC and Members API once deal data is available - if no deal - renders 404
    useEffect(() => {
        if (deal) {
            if (deal.borrowingEntities.length !== 0) {
                fetchDealMembers(id);
                fetchDealsLOC(deal.borrowingEntities[0]['BorrowingEntity__c']);
            } else {
                setMembers([]);
            }
        };
    }, [deal]);

    // reset the root deal container to top 
    useEffect(() => {
        document.getElementsByClassName("app-protected_side right")[0].scrollTo(0, 0)
    }, [location, id])


    // ----- LOC Modal
    // const LOCRedirectHandler = () => {
    //     setLOCRedirect(true);
    // }

    // const LOCModalBody = (
    //     <p>A Line of Credit is needed for this deal. <button onClick={LOCRedirectHandler}>Click here</button> to complete an application. </p>
    // )

    // const LOCModalContent = (
    //     <Section body={LOCModalBody} heading={"Line of Credit Required"} id="loc-required" />
    // )

    // const LOCModalClose = () => {
    //     setLOCNeeded(false);
    // }

    // ----- Main Content
    const content = (
        <div id="deals-dashboard">

            <Navigation accountType={props.accountType} id={id} deal={deal} />
            <Base>
                <Header deal={deal} />
                <Switch>
                    <RouteBasic exact path={dealURL}
                        title={`Deal Dashboard ${deal?.nickname ? " - " + deal.nickname : ""}`}
                        deal={deal}
                        fetchDeal={() => fetchDealDetails(id)}
                        members={members}
                        component={Root}
                        layout={Min} />

                    <RouteBasic exact path={dealURL + '/construction'}
                        title={`Deal Construction Management ${deal?.nickname ? " - " + deal.nickname : ""}`}
                        deal={deal}
                        component={Construction}
                        layout={Min} />

                    <RouteBasic exact path={dealURL + '/payment-history'}
                        title={`Deal Payment History ${deal?.nickname ? " - " + deal.nickname : ""}`}
                        deal={deal}
                        dealHistory={dealHistory}
                        component={PaymentHistory}
                        layout={Min} />

                    <RouteBasic exact path={dealURL + '/property'}
                        title={`Deal Properties ${deal?.nickname ? " - " + deal.nickname : ""}`}
                        deal={deal}
                        component={Property}
                        layout={Min} />

                    <RouteBasic exact path={dealURL + '/documents'}
                        title={`Deal Documents ${deal?.nickname ? " - " + deal.nickname : ""}`}
                        deal={deal}
                        component={Documents}
                        layout={Min} />

                    <RouteBasic exact path={dealURL + '/credit'}
                        title={`Deal Credit Card Information ${deal?.nickname ? " - " + deal.nickname : ""}`}
                        deal={deal}
                        component={Credit}
                        layout={Min} />

                    <RouteBasic path component={FourOhFour} layout={Min} {...props} />
                </Switch>
            </Base>

            {/* <DialogueBox visible={LOCNeeded} content={LOCModalContent} closeButton closeCallback={LOCModalClose} /> */}
        </div>
    );

    const errorMessage = (
        <TransitionIn content={
            <>
                We encountered a problem loading this deal (ID: {id}).
            </>
        } />
    );

    const contentError = (
        <div style={{ marginTop: "2rem" }}>
            <RouteBasic layout={Base} component={FourOhFour} message={errorMessage} accountType={props.accountType} {...props} />
        </div>
    )

    // fallback for invalid deals in URL
    return (
        <div>
            {deal !== false ? content : contentError}
        </div>
    )
}