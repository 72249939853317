import React, {useEffect} from 'react';
import LinkExpandable from '../components/LinkExpandable';
import LinkGeneric from '../components/LinkGeneric';
import LinkPaymentus from '../components/LinkPaymentus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartBar,
    faFileAlt,
    faHandHoldingUsd,
    faQuestionCircle,
    faSignOutAlt,
    faSyncAlt
} from '@fortawesome/free-solid-svg-icons';
import { ReactSVG } from 'react-svg';
import {withRouter} from "react-router-dom";

const NavBroker = props => {
    // getting all nav-related data and methods from props to prevent excess API calls
    const loansInProcess = props.nav.loansInProcess;
    const loansFunded = props.nav.loansFunded;
    const logout = props.nav.logout;

    useEffect( () => {
    });

    const pricingEngine = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="27.107" height="19.443" viewBox="0 0 27.107 19.443">
                <path className="a d"
                      d="M381.134,458.982H359.777a2.879,2.879,0,0,0-2.875,2.875V475.55a2.879,2.879,0,0,0,2.875,2.875h21.357a2.879,2.879,0,0,0,2.875-2.875V461.857A2.879,2.879,0,0,0,381.134,458.982Zm-21.357,1.75h21.357a1.127,1.127,0,0,1,1.125,1.125v1.294H358.652v-1.294A1.127,1.127,0,0,1,359.777,460.732Zm21.357,15.943H359.777a1.127,1.127,0,0,1-1.125-1.125v-8.277h23.607v8.277A1.127,1.127,0,0,1,381.134,476.675Z"
                      transform="translate(-356.902 -458.982)"/>
                <rect className="a d" width="10.391" height="1.75" transform="translate(4.138 11.109)"/>
                <rect className="a d" width="7.074" height="1.75" transform="translate(4.138 13.797)"/>
                <rect className="a d" width="2.242" height="1.75" transform="translate(20.197 11.109)"/>
            </svg>
        )
    }

    return (
        <ul>
            <li className={`main-nav-new_link`}>
                <LinkGeneric {...props} url={process.env.REACT_APP_SESSION_URL} text={`${props.accountType === "CustomerAccount" ? "Customer" : "Broker"} Dashboard`} icon={<FontAwesomeIcon icon={faChartBar} />} />
            </li>
            {(props.accountType !== "CustomerAccount" && process.env.REACT_APP_DISABLE_BROKER_APPS?.toString() !== 'true') &&
            <li className="main-nav-new_link">
                <LinkGeneric {...props} url={`${process.env.REACT_APP_SESSION_URL}/applications`} text={'New Applications'} icon={<FontAwesomeIcon icon={faFileAlt} />} />
            </li>
            }
            {props.user.isLead?.toString() !== "true" &&
            <li className="main-nav-new_link">
                <LinkExpandable {...props} loans={loansInProcess} text={'Loans in Process'} url={`${process.env.REACT_APP_SESSION_URL}/deal/:id`} icon={<FontAwesomeIcon icon={faSyncAlt} />} />
            </li>
            }
            {props.user.isLead?.toString() !== "true" &&
            <li className="main-nav-new_link">
                <LinkExpandable {...props} loans={loansFunded} text={'Funded Loans'} url={`${process.env.REACT_APP_SESSION_URL}/deal/:id`} icon={<FontAwesomeIcon icon={faHandHoldingUsd} />} />
            </li>
            }
            {process.env.REACT_APP_PRICING_ENGING === 'true' &&
            <li className="main-nav-new_link">
                <a href={`https://pricing.limaonecapital.com/login`} target={"_blank"}>
                    <div>
                        <span>{pricingEngine()}</span>
                        <span>Pricing Engine</span>
                    </div>
                </a>
            </li>
            }

            <li className="main-nav-new_link">
                <LinkGeneric {...props} url={`${process.env.REACT_APP_SESSION_URL}/faq`} text={'FAQ'} icon={<FontAwesomeIcon icon={faQuestionCircle} />} />
            </li>

            <li className="main-nav-new_link" >
                <button className="nav-item" role="button" onClick={logout}>
                    <span className="button-icon" style={{ width: '1.35em' }}><FontAwesomeIcon icon={faSignOutAlt} style={{ width: '1.5em' }} /></span>
                    <span>Logout</span>
                </button>
            </li>

        </ul>
    )
}



export default withRouter(NavBroker)