import React, {useEffect} from 'react';
import LinkExpandable from '../components/LinkExpandable';
import LinkGeneric from '../components/LinkGeneric';
import LinkPaymentus from '../components/LinkPaymentus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser, faChartBar, faCreditCard, faHammer, faSyncAlt, faFileAlt, faQuestionCircle, faFileMedical, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { ReactSVG } from 'react-svg';
import './index.scss';
import {withRouter} from "react-router-dom";


const NavCustomer = props => {
    // getting all nav-related data and methods from props to prevent excess API calls
    const loansInProcess = props.nav.loansInProcess;
    const loansFunded = props.nav.loansFunded;
    const loansConstruction = props.nav.loansConstruction;
    const logout = props.nav.logout;

    useEffect( () => {
    });

    return (
        <ul>
            <li className={`main-nav-new_link`}>
                <LinkGeneric {...props}
                    url={process.env.REACT_APP_SESSION_URL}
                    text={`${props.accountType === "CustomerAccount" ? "Customer" : "Broker"} Dashboard`}
                    icon={<FontAwesomeIcon icon={faChartBar} />} />
            </li>

            {props.user.isLead?.toString() !== "true" &&
            <li className="main-nav-new_link">
                <LinkPaymentus {...props}
                    text={'Make a Payment'}
                    icon={<FontAwesomeIcon icon={faCreditCard} />} />
            </li>
            }

            {props.user.isLead?.toString() !== "true" &&
            <li className="main-nav-new_link">
                <LinkExpandable {...props}
                    loans={loansInProcess}
                    text={'Loans in Process'}
                    url={`${process.env.REACT_APP_SESSION_URL}/deal/:id`}
                    icon={<FontAwesomeIcon icon={faSyncAlt} />} />
            </li>
            }

            {props.user.isLead?.toString() !== "true" &&
            <li className="main-nav-new_link">
                <LinkExpandable {...props}
                    loans={loansFunded}
                    text={'Funded Loans'}
                    url={`${process.env.REACT_APP_SESSION_URL}/deal/:id`}
                    icon={<FontAwesomeIcon icon={faHandHoldingUsd} />} />
            </li>
            }

            {props.user.isLead?.toString() !== "true" &&
            <li className="main-nav-new_link">
                <LinkExpandable {...props}
                    loans={loansConstruction}
                    text={'Construction'}
                    url={`${process.env.REACT_APP_SESSION_URL}/deal/:id/construction`}
                    icon={<FontAwesomeIcon icon={faHammer} />} />
            </li>
            }
            {(props.accountType === "CustomerAccount" && process.env.REACT_APP_DISABLE_CUSTOMER_APPS?.toString() !== 'true') &&
            <li className="main-nav-new_link">
                <LinkGeneric {...props}
                    url={`${process.env.REACT_APP_SESSION_URL}/applications`}
                    text={'New Applications'}
                    icon={<FontAwesomeIcon icon={faFileAlt} />} />
            </li>
            }

            {props.user.isLead?.toString() !== "true" &&
            <li className="main-nav-new_link">
                <LinkGeneric {...props}
                    url={`${process.env.REACT_APP_SESSION_URL}/servicing-requests`}
                    text={'Servicing Requests'}
                    icon={<FontAwesomeIcon icon={faFileMedical} />} />
            </li>
            }
            <li className="main-nav-new_link">
                <LinkGeneric {...props}
                    url={`${process.env.REACT_APP_SESSION_URL}/faq`}
                    text={'FAQ'}
                    icon={<FontAwesomeIcon icon={faQuestionCircle} />} />
            </li>

            <li className="main-nav-new_link profile">
                <LinkGeneric {...props}
                    url={`${process.env.REACT_APP_SESSION_URL}/profile`}
                    text={'Profile'}
                    icon={<FontAwesomeIcon icon={faUser} />} />
            </li>

            <li className="main-nav-new_link" >
                <button className="nav-item" role="button" onClick={logout}>
                    <span className="button-icon" style={{ width: '1.35em' }}><FontAwesomeIcon icon={faSignOutAlt} style={{ width: '1.5em' }} /></span>
                    <span>Logout</span>
                </button>
            </li>
        </ul>
    )
}

export default withRouter(NavCustomer)