import React from "react";
import Escrow from '../ConsutructionEscrow';
import ConstructionBudgetUpload from '../ConstructionBudgetUpload';
import ConstructionDraws from '../ConstructionDraws';
import axios from 'axios';
import Loader from 'react-loader-spinner'
import './index.scss';
import { log } from '../../../../../../utility'
import Skeleton from "react-loading-skeleton";

export default class Construction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealId: this.props.deal?.id,
      dealDraws: null,
      dealDrawStatus: true,
      dealDrawActive: null,
      dealDetails: {},
      intitialCurrentDraw: [],
      dealBudget: {
        totalBalance: 0,
        items: []
      },
      serverError: false,
      loadingDraws: true,
      demo: false
    }

    this.fetchDealDraws = this.fetchDealDraws.bind(this);
    this.fetchDealDetails = this.fetchDealDetails.bind(this);
    this.serverError = this.serverError.bind(this);
  }

  serverError(error, msg) {
    if (!msg) {
      msg = ""
    }

    this.setState({
      serverError: true
    })
  }

  fetchDealDraws() {

    if (this.state.dealDetails?.status === "Funded" && this.state.dealDetails?.property?.type === 'Multifamily') return

    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers['Content-Type'] = "application/json";

    axios.interceptors.request.use(req => {
      return req; // must return req
    });

    axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/draws', {
      dealid: this.state.dealId
    })
      .then((data) => {
        // console.log('draws', data);

        data.data.draws.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });

        let drawSheetStatus = true;

        data.data.draws.forEach(x => {
          if (x.status === "") {
            drawSheetStatus = false;
          }
        })

        this.setState({
          dealDraws: data.data.draws,
          dealDrawStatus: drawSheetStatus,
          loadingDraws: false,
          intitialCurrentDraw: data.data.draws.length ? data.data.draws[data.data.draws.length - 1] : [],
          dealDrawActive: data.data.draws.length ? data.data.draws.length - 1 : 0
        })
      })
      .catch(error => {
        this.serverError(error, 'draws');
        this.setState({
          dealDraws: false,
          serverError: true
        })
      });
  }

  fetchDealDetails() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers['Content-Type'] = "application/json";

    axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/details', {
      dealid: this.state.dealId
    })
      .then((data) => {
        this.setState({
          dealDetails: data.data.deal[0],
        })
      })
      .catch(function (error) {
        console.log(error);
        this.setState({
          serverError: true
        })
      });
  }

  componentDidMount() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
    axios.defaults.headers['Content-Type'] = "application/json";

    axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/details', {
      dealid: this.state.dealId
    })
      .then((data) => {
        let dealDetails = data.data.deal[0]
        this.setState({
          dealDetails: dealDetails,
        })

        if (dealDetails.status === "Funded" && (dealDetails?.type === 'Multifamily' || dealDetails?.property?.type === 'Multifamily')) return

        axios.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers['Content-Type'] = "application/json";

        axios.post(process.env.REACT_APP_LIMA + '/lima/api/deals/budget', {
          dealid: this.state.dealId
        })
          .then((data) => {
            // console.log(data.data.budget)
            // data.data.budget.totalBudget = parseFloat(data.data.budget.totalBudget).toFixed(2);
            // data.data.budget.totalBalance = parseFloat(data.data.budget.totalBalance).toFixed(2);
            // data.data.budget.items.forEach(x => {
            //   x.budget = parseFloat(x.budget).toFixed(2);
            //   x.balance = parseFloat(x.balance).toFixed(2);
            //   return x;
            // })
            data.data.budget.items = []
            data.data.budget.sublots.forEach(x => {
              x.items.forEach(y => {
                  y.budget = parseFloat(y.budget).toFixed(2);
                  y.balance = parseFloat(y.balance).toFixed(2);

                  data.data.budget.items.push(y)
              })
            })
            this.setState({
              dealBudget: data.data.budget
            })
          })
          .catch(error => {
            this.serverError(error, 'budget');
            this.setState({
              serverError: true
            })
          });

        this.fetchDealDraws();

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { dealId, dealDraws, dealDetails, dealDrawStatus, intitialCurrentDraw, dealBudget, dealDrawActive, serverError } = this.state;

    if (dealDetails.status === "Funded" && (dealDetails?.type === 'Multifamily' || dealDetails?.property?.type === 'Multifamily')) {
      return (
        <div>
          <main className="construction-subroute">
            <div className="construction-wrapper_inner">
              <div>
                <h3>Please contact your Construction Analyst to request a draw.</h3>
              </div>
            </div>
          </main>
        </div>
      )
    }

    if(!dealDetails.status) {
      return <Skeleton height={"40rem"} />
    };

    return (
      <div>
        <main className="construction-subroute">
          <div className="construction-wrapper_inner">
            <div>
              {
                // If deal is not funded, display Budget Upload...
                dealDetails.status !== "Funded" && !(
                  dealDetails?.type === "Short Term Finance" ||
                  dealDetails?.type === "NewConstruction" ||
                  dealDetails?.type === "New Construction" ||
                  dealDetails?.type === "FixNFlip"
                )
                ?
                  <ConstructionBudgetUpload
                    dealID={dealId}
                    dealDetails={dealDetails}
                    fetchDealDetails={this.fetchDealDetails}

                  /> :
                  // else display Escrow and Draw Sheet
                  <div className="construction-funded">
                    <Escrow
                      dealID={this.props.dealid}
                      dealDraws={dealDraws}
                      dealBudget={dealBudget}
                    />
                    <ConstructionDraws
                      dealId={dealId}
                      dealDraws={dealDraws}
                      dealDetails={dealDetails}
                      dealBudget={dealBudget}
                      dealDrawActive={dealDrawActive}
                      intitialCurrentDraw={intitialCurrentDraw}
                      fetchDealDraws={this.fetchDealDraws}
                      dealDrawStatus={dealDrawStatus}
                      serverError={this.state.serverError}
                      loadingDeals={this.state.loadingDraws}
                    />

                  </div>
              }

            </div>
          </div>
        </main>
      </div>
    );
  }
}